import { Col, Row, Image } from 'react-bootstrap';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import WalttyStickerWhite from '../images/waltty_sticker_white.png';

function About() {
  return (
    <Layout>
      <SEO title="About" />
      <Row className="text-center container mx-auto py-5">
        <Col>
          <Row>
            <Col>
              <h1>About Waltty</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>
                Our goal is simple: to create something touching in playing cards.
              </h5>
            </Col>
          </Row>
          <Row>
            <Col as="p" xs lg="8" className="mx-auto">
              Waltty Playing Card Studio was founded in the end of 2015 by six
              friends in Taiwan. We have been doing magic, cardistry and
              collecting playing cards for almost 10 years. We believe that is an
              important part of our lives. Everyone who loves playing cards has
              his or her style. We want to share our ideas with you on this unique
              lifestyle.
            </Col>
          </Row>
          <Row className="mb-5">
            <Col as="p" xs lg="8" className="mx-auto">
              {`We decided to make a unique deck we love for our stories. We
            started a plan for our first deck, The Waltty Deck. After that,
            we'd like to bring more ideas to life. Our goal is simple: to
            create something touching in playing cards.`}
            </Col>
          </Row>
          <Row>
            <Col>
              <Image src={WalttyStickerWhite} alt="Waltty" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

export default About;
